<template>
      <b-container class="pr-0">
        <b-row class="center mx-2 mb-5">
            <b-col cols="12">
                <h1>{{item.itemTitle}}</h1>
            </b-col>
        </b-row>
        <b-row class="center mx-2">
            <b-col cols="8" class="px-0">
                <gallery :images="item.images"
                    :index="index"
                    @close="index = null"
                    :options="galleryOptions">
                </gallery>
                <div
                class="image"
                v-for="(image,imageIndex) in item.images"
                :key="imageIndex"
                @click="index=imageIndex"
                :style="{ backgroundImage: 'url(' + image + ')', width: '50%', height: '300px' }"
                ></div>
            </b-col>
            <b-col cols="4" class="galleryItemDescription">
                <h5>{{item.itemText}}</h5>
            </b-col>
        </b-row>

    </b-container>
</template>



<script>
import VueGallery from 'vue-gallery'
import Vue from 'vue'
Vue.Use(VueGallery);

export default {
    components: {
        'gallery': VueGallery
    },
    data () {
        return {
            item : {},
            index: null,
            galleryOptions : {
                carousel : true,
                displayClass: "blueimp-gallery-display viewer"
            }
        }
    },
    mounted() {
        this.getGalleryItem();
    },
    computed : {

    },
    methods : {
        getGalleryItem() {
            var id = this.$route.query.Id;
            this.$store.dispatch("getGalleryItem", id)
            .then(response => response.json())
            .then (result => {
                this.item = result;
            });
        }
    }
}
</script>

<style>
.image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: solid white;
    transition: all .1s ease;
  }
.image:hover {
    border: 5px solid #FF005D;
}
.galleryItemDescription {
    color: slategrey;
    padding: 5px;
}
.viewer {
    background-color: rgba(0, 0, 0, .5);
}
</style>